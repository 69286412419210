import { Component, NgZone, OnInit } from '@angular/core'

import 'annyang'
import { MeasurementUnit, parseInput } from './parse-input'

declare var SpeechKITT: any
declare var annyang: any

enum State {
  IDLE = 'idle',
  WAITING = 'waiting for speech',
  LISTENING = 'listening',
  ERROR = 'error',
}


@Component({
  selector: 'app-speech',
  templateUrl: './speech.component.html',
  styleUrls: ['./speech.component.scss']
})
export class SpeechComponent implements OnInit {

  public state: State = State.IDLE
  public results: Array<{ text: string, others: Array<string> }> = []

  public measurements: Array<{ value: number, unit: MeasurementUnit, originalCommand: string }> = []

  public annyang = annyang

  constructor(private ngZone: NgZone) {
  }

  ngOnInit(): void {

    annyang.setLanguage('de-DE')

    annyang.addCallback('error', (err) => {
      console.log('ERROR', err)
      this.state = State.ERROR
      annyang.abort()
    })

    // Soundstart
    annyang.addCallback('soundstart', (res) => {
      this.ngZone.run(() => {
        this.state = State.LISTENING
      })
    })

    // Start: Fired as soon as the browser's Speech Recognition engine starts listening.
    annyang.addCallback('start', (res) => {
      this.ngZone.run(() => {
        this.state = State.WAITING
      })
    })

    /*
    Matches don't work if we speek multiple numbers at once

    annyang.addCallback('resultMatch', function (userSaid, commandText, phrases) {
      console.log('RESULT MATCH', {userSaid, commandText, phrases})
    })

    annyang.addCallback('resultNoMatch', function (phrases) {
      console.log('I think the user said: ', phrases[0])
      console.log('But then again, it could be any of the following: ', phrases)
    })

     */


    // Sound end
    annyang.addCallback('end', (stuff) => {
      annyang.abort()
    })

    // Result
    annyang.addCallback('result', (userSaid) => {

      // Split the number if multiple once are here
      // Example: "17,3 cm 14 mm"
      const input = userSaid[0]

      const parsedInput = parseInput(input)

      for (let found of parsedInput.measurements) {
        this.measurements.push({
          ...found,
          originalCommand: input
        })
      }

      this.ngZone.run(() => {
        this.state = State.IDLE
        this.results.push({text: userSaid[0], others: userSaid})

        annyang.pause()
      })

      annyang.abort()
    })
  }


  startVoiceRecognition(): void {
    this.state = State.IDLE

    if (annyang) {
      let commands = {

        /*
         Matches don't work if we speek multiple numbers at once
        ':value cm': (args) => {
          console.log('in cm command', { args })
        },

        ':value centimeter': (args) => {
          console.log('in centimeter command', { args })
        },

        ':value zentimeter': (args) => {
          console.log('in zentimeter command', { args })
        },

        ':value mm': (args) => {
          console.log('in mm command', { args })
        },

        ':value Millimeter': (args) => {
          console.log('in Millimeter command', { args })
        },*/


        'hallo': () => {
          console.log('in HALLO command')
        }
      }

      annyang.addCommands(commands)

      // annyang.debug()


      annyang.start({autoRestart: true, continuous: true})
    }
  }

  closeVoiceRecognition(): void {
    if (annyang) {
      annyang.abort()
    }
  }

  transformToMillimeters({ value, unit }: { value: number, unit: MeasurementUnit }): number {
    let multiplier

    switch (unit) {
      case 'cm':
        multiplier = 10
        break
      case 'm':
        multiplier = 1000
        break
      default:
        multiplier = 1
    }

    return multiplier * value

  }
}
