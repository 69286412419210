<div class="header">
  <span>Anschlagseite: {{ anschlagLinks ? "links":"rechts"}}</span>
  <button style="margin-left: 5px" class="btn btn-success" (click)="anschlagLinks = !anschlagLinks">FLIP</button>
</div>


<div id="demoRoot">

  <div id="gesture-area">
    <div *ngIf="isDesktop" class="resize-handle"><i class="fas fa-expand-alt fa-rotate-90"></i></div>
    <div *ngIf="isDesktop" class="rotation-handle"><i class="fas fa-redo"></i></div>
    <img  src="../../../assets/{{getImageSrc()}}" id="scale-element"/>
  </div>

</div>
