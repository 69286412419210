<!--<button (click)="onClick()" class="speech-button">🎙</button>-->


<div class="row">

  <div class="col-md-12">

    <h3 class="mt-20"><strong>State:</strong><code> {{ state }}</code></h3>
    <button class="speech-button"
            [ngClass]="{'listening': state === 'waiting for speech', 'hearing': state === 'listening', 'idle': state === 'idle' }"
            type="button" (click)="startVoiceRecognition()">🎙
    </button>

    <p style="display: inline" class="text-muted">Drücke den Button und sage deine Messwerte inkl. der Einheit. Z.b: "17.4 Zentimeter, 15 millimeter, 1.25 meter.
      Eine Eingabe ohne Einheit wird ignoriert</p>


  </div>

</div>


<div class="row mb-4">

  <div class="col-md-12">

    <h3>Parsed measurements</h3>
    <span class="text-sm text-muted">Hier findest du alle durch die Spracheingabe gefundenen Messungen</span>
    <br>
    <br>

    <table class="table table-sm table-bordered table-hover">
      <thead class="thead-dark">
      <tr>
        <td><strong>Value</strong></td>
        <td><strong>Unit</strong></td>
        <td><strong>Technical output [mm]</strong></td>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor="let measure of measurements">
        <td><code>{{ measure.value }}</code></td>
        <td><code>{{ measure.unit }}</code></td>
        <td><code>{{ transformToMillimeters(measure) }} mm</code></td>
      </tr>
      </tbody>

    </table>

  </div>

</div>


<div class="row mt-4">

  <div class="col-md-12">

    <h3>Speech inputs</h3>
    <span class="text-sm text-muted">Hier findest du alle Spracheingaben</span>
    <br>
    <br>


    <table class="table table-sm table-bordered table-hover">
      <thead class="thead-dark">
      <tr>
        <td><strong>Original command</strong></td>
        <td><strong>Found alternatives</strong></td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let result of results">
        <td>{{ result.text }}</td>
        <td><code>{{ result.others }}</code></td>
      </tr>
      </tbody>

    </table>
  </div>

</div>
