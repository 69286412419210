export type MeasurementUnit = 'mm' | 'cm' | 'm'


/**
 * Parses the input from the speech recognition to actual measurements with units
 * @param input
 * @private
 */
export function parseInput(input: string): {
  measurements: Array<{ value: number, unit: MeasurementUnit }>
} {
  // Example: "17,3 cm 14 mm"

  input = input.toLowerCase().replace('meter', 'm')

  const measurements: Array<{ value: number, unit: MeasurementUnit }> = []

  const regex = /\d+,?\d* (mm|cm|m)/g

  let match
  while ((match = regex.exec(input)) !== null) {

    // Get the whole match: "17,3 cm"
    const wholeMatch = match[0]

    // Units part: "cm"
    const unitsPart = match[1]

    // Parse the units part to a unit
    const unit = parseUnitPart(unitsPart)

    // Get only the value part of the wholeMatch by removing the unit and trimming it
    // Result will be: "17,3"
    const valuePartString = wholeMatch
      .replace(unitsPart, '')
      .trim()

    // Replace commas with points and parse it to a number
    // Result will be: 17.3 as an js-number
    const valueNumber: number = Number(valuePartString.replace(',', '.'))

    measurements.push({
      value: valueNumber,
      unit
    })
  }

  return {measurements}
}


function parseUnitPart(unitString: string): MeasurementUnit {
  switch (unitString) {
    case 'cm':
      return 'cm'

    case 'mm':
      return 'mm'

    case 'm':
      return 'm'

    default:
      throw new Error(`Unknown unit '${unitString}'`)
  }

}
