import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DoorPlanCreationComponent } from './components/door-plan-creation/door-plan-creation.component'
import { SpeechComponent } from './components/speech/speech.component'

const routes: Routes = [
  {
    path: 'door-plan-creation',
    component: DoorPlanCreationComponent
  },
  {
    path: 'speech',
    component: SpeechComponent
  },
  {
    path: '**',
    redirectTo: '/door-plan-creation'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
