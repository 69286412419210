import { Component, OnInit } from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'
import interact from 'interactjs'

@Component({
  selector: 'app-door-plan-creation',
  templateUrl: './door-plan-creation.component.html',
  styleUrls: ['./door-plan-creation.component.scss']
})
export class DoorPlanCreationComponent implements OnInit {
  title = 'dpc-test';
  public isDesktop = true

  constructor( private deviceService: DeviceDetectorService) {
    this.isDesktop = !deviceService.isMobile();
  }


  public anschlagLinks = true;
  public getImageSrc() {
    return this.anschlagLinks ? 'door.png' : 'doorR.png'
  }

  ngOnInit(): void {

    var angleScale = {
      angle: 0,
      scale: 1
    }

    var gestureArea = document.getElementById('gesture-area')
    var scaleElement = document.getElementById('scale-element')
    var resetTimeout

    if (!this.isDesktop) {
      interact(gestureArea)

        .gesturable({
          listeners: {
            start (event) {
              angleScale.angle -= event.angle

              // clearTimeout(resetTimeout)
              scaleElement.classList.remove('reset')
            },
            move (event) {
              // document.body.appendChild(new Text(event.scale))
              var currentAngle = event.angle + angleScale.angle
              var currentScale = event.scale * angleScale.scale


              var target = event.target
              var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
              var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

              // translate the element
              target.style.transform = 'translate(' + x + 'px, ' + y + 'px)' +  'rotate(' + currentAngle + 'deg)' + 'scale(' + currentScale + ')'

              // update the posiion attributes
              target.setAttribute('data-x', x)
              target.setAttribute('data-y', y)
            },
            end (event) {
              angleScale.angle = angleScale.angle + event.angle
              angleScale.scale = angleScale.scale * event.scale

              scaleElement.classList.add('reset')
            }
          }
        })

        .draggable({
          listeners: { move: (event) => {

              var currentAngle = angleScale.angle
              var currentScale = angleScale.scale

              var target = event.target
              // keep the dragged position in the data-x/data-y attributes
              var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
              var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

              // translate the element
              target.style.transform = 'translate(' + x + 'px, ' + y + 'px)' +  'rotate(' + currentAngle + 'deg)' + 'scale(' + currentScale + ')'

              // update the posiion attributes
              target.setAttribute('data-x', x)
              target.setAttribute('data-y', y)
            } }
        })
    }



    if (this.isDesktop) {

      interact(gestureArea).resizable({
        // resize from all edges and corners
        edges: {
          bottom: '.resize-handle',
          right: '.resize-handle',
          top: '.resize-handle',
          left: '.resize-handle'
        },
        squareResize: true,
        square: true,

        listeners: {
          move (event) {


            var angle = getDragAngle(event);

            var currentAngle = event.angle + angleScale.angle
            var currentScale = event.scale * angleScale.scale

            var target = event.target
            var x = (parseFloat(target.getAttribute('data-x')) || 0)
            var y = (parseFloat(target.getAttribute('data-y')) || 0)

            // update the element's style
            target.style.width = event.rect.width + 'px'
            target.style.height = event.rect.height + 'px'

            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top

            target.style.transform = 'translate(' + x + 'px, ' + y + 'px)' +  'rotate(' + angle + 'rad)' + 'scale(' + currentScale + ')'

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            //  target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
          }
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent'
          }),

          interact.modifiers.aspectRatio({
            enabled: true,
            ratio: 'preserve'
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 100, height: 100 }
          })
        ],

        inertia: true
      })
        .draggable({
          listeners: { move: (event) => {

              var target = event.target



              var box = target
              var currentAngle = parseFloat(box.getAttribute('data-angle')) || 0;
              var center = {
                x: parseFloat(box.getAttribute('data-center-x')) || 0,
                y: parseFloat(box.getAttribute('data-center-y')) || 0
              };
              var angle = Math.atan2(center.y, center.x);

              //   var currentAngle = angle - startAngle;



              var currentScale = angleScale.scale

              // keep the dragged position in the data-x/data-y attributes
              var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
              var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

              // translate the element
              target.style.transform = 'translate(' + x + 'px, ' + y + 'px)' +  'rotate(' + currentAngle + 'rad)' + 'scale(' + currentScale + ')'

              // update the posiion attributes
              target.setAttribute('data-x', x)
              target.setAttribute('data-y', y)
            } }
        })



      function getDragAngle(event) {
        var box = event.target.parentElement;
        var startAngle = parseFloat(box.getAttribute('data-angle')) || 0;
        var center = {
          x: parseFloat(box.getAttribute('data-center-x')) || 0,
          y: parseFloat(box.getAttribute('data-center-y')) || 0
        };
        var angle = Math.atan2(center.y - event.clientY,
          center.x - event.clientX);

        return angle - startAngle;
      }



      interact('.rotation-handle')
        .draggable({
          onstart: function(event) {
            var box = event.target.parentElement;
            var rect = box.getBoundingClientRect();

            // store the center as the element has css `transform-origin: center center`
            box.setAttribute('data-center-x', rect.left + rect.width / 2);
            box.setAttribute('data-center-y', rect.top + rect.height / 2);
            // get the angle of the element when the drag starts
            box.setAttribute('data-angle', getDragAngle(event));
          },
          onmove: function(event) {
            var box = event.target.parentElement;

            var pos = {
              x: parseFloat(box.getAttribute('data-x')) || 0,
              y: parseFloat(box.getAttribute('data-y')) || 0
            };

            var angle = getDragAngle(event);

            // update transform style on dragmove
            box.style.transform = 'translate(' + pos.x + 'px, ' + pos.y + 'px) rotate(' + angle + 'rad' + ')';
          },
          onend: function(event) {
            var box = event.target.parentElement;

            // save the angle on dragend
            box.setAttribute('data-angle', getDragAngle(event));
          },
        })






    }
  }

  public resizeListener(event) {
    var target = event.target
    var x = (parseFloat(target.getAttribute('data-x')) || 0)
    var y = (parseFloat(target.getAttribute('data-y')) || 0)

    // update the element's style
    target.style.width = event.rect.width + 'px'
    target.style.height = event.rect.height + 'px'

    // translate when resizing from top or left edges
    x += event.deltaRect.left
    y += event.deltaRect.top

    target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
    // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
  }

  public dragMoveListener (event) {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
  }

}
